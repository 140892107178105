<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/managers', name: 'manager' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'Manager'
}
</script>
